
<template>
  <v-container>
    <v-alert text prominent type="error" icon="mdi-cloud-alert" v-if="alertError!=''">
      {{alertError}}
    </v-alert>
    <v-alert text prominent type="success" icon="mdi-cloud-alert" v-if="alertSuccess!=''">
      {{alertSuccess}}
    </v-alert>
    <template>
      <v-data-table :headers="tableHeader" :items="users" sort-by="Name" :item-class="itemDisable" class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Manage Schools Admin</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialogUser" max-width="1000px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                  New School Admin
                </v-btn>
              </template>
              <v-card>
                <v-form ref="form" v-model="valid"  @submit.prevent="save" lazy-validation>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field v-model="user.name" :rules="nameRules" label="Name" prepend-icon="mdi-account" required></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field v-model="user.lastName" :rules="nameRules" label="Last Name" prepend-icon="mdi-account" required></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-select v-model="user.country" :items="countries" menu-props="auto" item-text="name" item-value="id" label="Country" @change="SelectState"  hide-details prepend-icon="mdi-map"  single-line></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-select v-model="user.state" :items="states" menu-props="auto" item-text="name" item-value="id" label="States" @change="SelectSchoolDistrict"  hide-details prepend-icon="mdi-map"  single-line :disabled="states.length==0"></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-select v-model="user.schoolDistrict" :items="schoolDistricts" id="schoolDistrictText" menu-props="auto" item-text="name" item-value="id" label="School Districts" @change="SelectSchool"  hide-details prepend-icon="mdi-map" return-object single-line :disabled="schoolDistricts.length==0"></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-select v-model="user.school" :items="schools" id="schoolText" menu-props="auto" item-text="name" item-value="id" label="Schools" hide-details prepend-icon="mdi-map" return-object single-line :disabled="schools.length==0" @change="ChooseSchool"></v-select>
                      </v-col>
                      <!-- <v-col cols="12" sm="12" md="12">
                        <v-menu v-model="PickerExpiration" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="user.PickerExpiration" label="Date Expiration" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                          </template>
                          <v-date-picker v-model="user.PickerExpiration" @input="PickerExpiration = false"></v-date-picker>
                        </v-menu>
                      </v-col> -->
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field v-model="user.email" :rules="emailRules" prepend-icon="mdi-at" label="Email"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" v-if="editedUID == -1">
                        <v-text-field v-model="user.password" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :rules="[passwordRules.required, passwordRules.min]" :type="showPassword ? 'text' : 'password'" label="Password" counter @click:append="showPassword = !showPassword"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                   <v-card-text class="subtitle-1">Expiration Date: {{ user.PickerExpiration }}</v-card-text>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text type="submit">
                    Save
                  </v-btn>
                </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDisable" max-width="500px">
              <v-card>
                <v-card-title class="headline">Are you sure you want to disable?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDisable">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="disableUserConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogEnable" max-width="500px">
              <v-card>
                <v-card-title class="headline">Are you sure you want to enable?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeEnable">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="enableUserConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          
          <v-icon small class="mr-2" @click="editUser(item)" v-if="item.Enabled">
            mdi-pencil
          </v-icon>
          <v-icon small @click="disableUser(item)" v-if="item.Enabled">
            mdi-account-off
          </v-icon>
          <!--<v-icon small class="ml-4" color="green accent-4" @click="enableUser(item)" v-else>
            mdi-account-check
          </v-icon>-->
          <v-tooltip top v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="ml-4" color="green accent-4" v-bind="attrs" v-on="on" @click="enableUser(item)" >
                mdi-account-check
              </v-icon>
            </template>
            <span>You can enable the user here.</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </template>
    <v-dialog v-model="dialogLoad" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Wait please
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-bottom-sheet v-model="sheet">
      <v-sheet
        class="text-center"
        height="200px"
      >
        <v-btn
          class="mt-6"
          text
          color="red"
          @click="sheet = !sheet"
        >
          close
        </v-btn>
        <div class="py-3">
          This user is disabled
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </v-container>
</template>
<script>
import { db, functions } from '../../../plugins/firebase'
export default {
  data () {
    return {
      tableHeader: [
        { text: 'Name', value: 'Name' },
        { text: 'Last Name', value: 'LastName' },
        { text: 'Email', value: 'Email' },
        { text: 'SchoolDistrict', value: 'SchoolDistrict' },
        { text: 'School', value: 'School' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      users: [],
      user: {
        name: '',
        lastName: '',
        country: '',
        state: '',
        schoolDistrict: '',
        school: '',
        PickerExpiration: '',
        email: '',
        password: '',
      },
      nameRules: [
        v => (v && v.length >= 2) || 'Minimum length is 2 characters',
        v => (v && v.length <= 30) || 'Name must be less than 30 characters'
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 4 || 'Min 4 characters',
        emailMatch: () => (`The email and password you entered don't match`),
      },
      clearUser: {
        name: '',
        lastName: '',
        country: '',
        state: '',
        schoolDistrict: '',
        school: '',
        PickerExpiration: '',
        email: '',
        password: '',
      },
      countries: [],
      states: [],
      schoolDistricts: [],
      schools: [],
      editedUID: -1,
      dialogUser: false,
      dialogDisable: false,
      dialogEnable: false,
      dialogLoad: false,
      valid: true,
      PickerExpiration: false,
      alertError: '',
      alertSuccess: '',
      showPassword: false,
      table: 'SchoolAdmin',
      sheet: false
    }
  },
  created () {
    // console.log(this.$store.state.users.user.uid)
    this.Users();
    this.ListCountries();
  },
  methods: {
    // ListStudents () {
    //   this.getStudents().then(response => {
    //     this.students = response;
    //   });
    // },
    editUser (data) {
      console.log(data)
      const path = data.Reference.path;
      let subdata = path.split("/");
      if (subdata.length < 7) {
        return;
      }
      this.user.country = subdata[3];
      this.SelectState().then(() => {
        this.user.state = subdata[5];
        this.SelectSchoolDistrict().then(() => {
          let schoolDistr = this.schoolDistricts.find(x => x.id === subdata[7]);
          // if (findCountry === -1) {
          //   this.alertError = 'country not found';
          //   return;
          // }
          this.user.schoolDistrict = schoolDistr;
          this.SelectSchool().then(() => {
            // this.user.school = subdata[9];
            let schoolAux = this.schools.find(x => x.id === subdata[9]);
            this.user.school = schoolAux;
          });
        });
      });
      this.editedUID = data.id;
      this.user.id = data.id;
      this.user.name = data.Name;
      this.user.lastName = data.LastName;
      this.user.PickerExpiration = data.PickerExpiration.toDate().toDateString();
      this.user.TimestampExpiration = data.TimestampExpiration;
      this.user.email = data.Email;
      // this.user = Object.assign({}, data)
      this.dialogUser = true
    },
    disableUser (data) {
      this.editedUID = data.id;
      this.user = Object.assign({}, data)
      this.dialogDisable = true
    },
    enableUser (data) {
      this.editedUID = data.id;
      this.user = Object.assign({}, data)
      this.dialogEnable = true
    },
    disableUserConfirm () {
      this.statusData(this.editedUID, false).then((answer) => {
        if (answer.result) {
          this.alertSuccess = "disable successfully";
          let principal = this;
          setTimeout(function () { principal.alertSuccess = '' }, 3000);
        } else {
          this.alertError = answer.message;
        }
      });
      this.closeDisable()
    },
    enableUserConfirm () {
      this.statusData(this.editedUID, true).then((answer) => {
        if (answer.result) {
          this.alertSuccess = "enable successfully";
          let principal = this;
          setTimeout(function () { principal.alertSuccess = '' }, 3000);
        } else {
          this.alertError = answer.message;
        }
      });
      this.closeEnable()
    },
    close () {
      this.dialogUser = false
      this.$refs.form.resetValidation()
      this.$nextTick(() => {
        this.user = Object.assign({}, this.clearUser)
        this.editedUID = -1
      })
    },
    closeDisable () {
      this.dialogDisable = false
      this.$nextTick(() => {
        this.user = Object.assign({}, this.clearUser)
        this.editedUID = -1
      })
    },
    closeEnable () {
      this.dialogEnable = false
      this.$nextTick(() => {
        this.user = Object.assign({}, this.clearUser)
        this.editedUID = -1
      })
    },
    save () {
      if (!this.$refs.form.validate()) return;
      if (this.editedUID !== -1) {
        this.dialogLoad = true;
        this.updateData(this.user).then(response => {
          console.log(response);
          if (response.result) {
            this.alertSuccess = "se actualizo correctamente ";
            let principal = this;
            setTimeout(function () { principal.alertSuccess = '' }, 3000);
          } else {
            let principal = this;
            if (Object.prototype.hasOwnProperty.call(response.message, 'code')) {
              switch (response.message.code) {
                case 'not-found':
                  this.alertError = 'no se encontro el documento';
                  break;
                default:
                  this.alertError = response.message.code;
              }
            } else {
              this.alertError = response.message;
            }
            setTimeout(function () { principal.alertError = '' }, 3000);
          }
          this.dialogLoad = false
        });
      } else {
        this.dialogLoad = true;
        this.createData(this.user).then(response => {
          console.log(response);
          if (!response.result) {
            this.alertError = response.message;
            let principal = this;
            setTimeout(function () { principal.alertError = '' }, 3000);
          } else {
            this.alertSuccess = "se creo correctamente ";
            let principal = this;
            setTimeout(function () { principal.alertSuccess = '' }, 3000);
          }
          this.dialogLoad = false
        });
      }
      this.close()
      this.$refs.form.resetValidation()
    },
    validate () {
      this.$refs.form.validate()
    },
    itemDisable(item) {
      return !item.Enabled? 'disableUser' : ''
    },
    // llamadas a firebase
    async createData (user) {
      let answer = { result: '', message: '' }
      try {
        let uidSuperAdmin = this.$store.state.user;
        if (uidSuperAdmin == null) {
          throw new Error('no existe el usuario');
        }
        console.log(user)
        user.table = this.table;
        const setAdmin = functions.httpsCallable('createUserPrivate')
        await setAdmin(user)
          .then((userCreated) => {
            console.log(userCreated)
            let map ={
              Name: userCreated.data.user.displayName,
              LastName: user.lastName,
              Email: user.email
            };
            const batch = db.batch()
            let step1 = db.collection(this.table).doc(userCreated.data.user.uid);
            let SchoolReference = db.doc('SchoolsData/RegionSchoolD/Countries/' + user.country + '/States/' + user.state + '/SchoolDistricts/' + user.schoolDistrict.id + '/Schools/' + user.school.id)
            batch.update(step1, {
              "LastName": user.lastName,
              "SchoolDistrict": user.schoolDistrict.name,
              "School": user.school.name,
              "PickerExpiration": user.TimestampExpiration,
              "Reference": SchoolReference,
              "Enabled": true
            });
            let step2 = SchoolReference
            batch.update(step2, { [`SchoolAdmins.${[userCreated.data.user.uid]}`]: map });
            batch.commit().then(() => {
              answer.result = true;
            });
          })
      } catch (error) {
        answer.result = false;
        answer.message = error;
        // console.log(error);
      }
      return answer;
    },
    async updateData (user) {
      console.log(user)
      let answer = { result: '', message: '' }
      try {
        user.table = this.table;
        const updatetAdmin = functions.httpsCallable('updateUserPrivate')
      
        let userCreated=await updatetAdmin(user);
        console.log(userCreated)
        let map = {
            Name: userCreated.data.user.displayName,
            LastName: user.lastName,
            Email: user.email
        };
        const batch = db.batch()
        let step1 = db.collection(this.table).doc(userCreated.data.user.uid);
        let SchoolReference = db.doc('SchoolsData/RegionSchoolD/Countries/' + user.country + '/States/' + user.state + '/SchoolDistricts/' + user.schoolDistrict.id + '/Schools/' + user.school.id)
        batch.update(step1, {
          "LastName": user.lastName,
          "SchoolDistrict": user.schoolDistrict.name,
          "School": user.school.name,
          //"PickerExpiration": user.TimestampExpiration,
          "Reference": SchoolReference
        });
        let step2 = SchoolReference
        batch.update(step2, { [`SchoolAdmins.${[userCreated.data.user.uid]}`]: map });
        batch.commit().then(() => {
          answer.result = true;
        });
        // await db.collection("schoolAdmin").doc(user.id).update(user)
        //   .then(() => {
        //     answer.resultado = true;
        //   })
        //   .catch((error) => {
        //     answer.resultado = false;
        //     answer.mensaje = error;
        //   });
      } catch (error) {
        answer.result = false;
        answer.message = error;
        console.log('entro al log general');
      }
      return answer;
    },
    async statusData (id, status) {
      let answer = { resutl: '', message: '' }
      let uidLogin = this.$store.state.user.uid;
      if (uidLogin === id) {
        answer.mensaje = 'this user is actually login';
        answer.resultado = false;
        return answer;
      }
      try {
        let statusAdmin=null;
        if (status)
          statusAdmin = functions.httpsCallable('enableSchoolAdmin');
        else
          statusAdmin = functions.httpsCallable('disableSchoolAdmin');
        await statusAdmin(id)
          .then(() => {
            answer.result = true;
          })
      } catch (error) {
        answer.message = error;
        answer.resutl = false;
      }
      return answer;
    },
    async Users () {
      await db.collection(this.table).onSnapshot((snapshotChange) => {
        this.users = [];
        snapshotChange.forEach((doc) => {
          console.log(doc.data())
          let oneStudent = doc.data();
          oneStudent.id = doc.id;
          this.users.push(oneStudent);
        });
      }, (error) => {
        console.log(error);
      })
    },
    async ListCountries () {
      await db.collection('SchoolsData').doc('RegionSchoolD').get().then((doc) => {
        let Countries = doc.data().Countries;
        let ids = Object.keys(Countries);
        let newCountries = [];
        ids.forEach(element => {
          newCountries.push({ id: element, name: Countries[element] })
        });
        this.countries = newCountries;
      }).catch((error) => {
        console.log(error)
      });
    },
    async SelectState () {
      let StateReference = db.collection('SchoolsData').doc('RegionSchoolD').collection('Countries').doc(this.user.country);
      await StateReference.get().then((doc) => {
        let ListStates = doc.data().States;
        let ids = Object.keys(ListStates);
        let newStates = [];
        ids.forEach(element => {
          newStates.push({ id: element, name: ListStates[element] })
        });
        this.states = newStates;
        // aqui reiniciamos los demas selects
        this.schoolDistricts = [];
        this.schools = [];
        this.user.schoolDistrict = '';
        this.user.school = '';
      }).catch((error) => {
        console.log(error)
      });
    },
    async SelectSchoolDistrict () {
      let SchoolDistrictReference = db.collection('SchoolsData').doc('RegionSchoolD').collection('Countries').doc(this.user.country).collection('States').doc(this.user.state);
      await SchoolDistrictReference.get().then((doc) => {
        let ListSchoolDistrict = doc.data().SchoolDistricts;
        let ids = Object.keys(ListSchoolDistrict);
        let newSchoolDistricts = [];
        ids.forEach(element => {
          newSchoolDistricts.push({ id: element, name: ListSchoolDistrict[element] })
        });
        this.schoolDistricts = newSchoolDistricts;
        // aqui reiniciamos los demas selects
        this.schools = [];
        this.user.school = '';
      }).catch((error) => {
        console.log(error)
      });
    },
    async SelectSchool () {
      let SchoolReference = db.collection('SchoolsData').doc('RegionSchoolD').collection('Countries').doc(this.user.country).collection('States').doc(this.user.state).collection('SchoolDistricts').doc(this.user.schoolDistrict.id);
      await SchoolReference.get().then((doc) => {
        let ListSchool = doc.data().Schools;
        let ids = Object.keys(ListSchool);
        let newSchools = [];
        ids.forEach(element => {
          newSchools.push({ id: element, name: ListSchool[element] })
        });
        this.schools = newSchools;
      }).catch((error) => {
        console.log(error)
      });
    },
    async ChooseSchool () {
      let SchoolReference = db.collection('SchoolsData').doc('RegionSchoolD').collection('Countries').doc(this.user.country).collection('States').doc(this.user.state).collection('SchoolDistricts').doc(this.user.schoolDistrict.id).collection('Schools').doc(this.user.school.id);
      await SchoolReference.get().then((doc) => {
        this.user.PickerExpiration = doc.data().DateExpired.toDate().toDateString();
        this.user.TimestampExpiration = doc.data().DateExpired
      }).catch((error) => {
        console.log(error)
      });
    }
  },
  computed: {
    formTitle () {
      return this.editedUID === -1 ? 'New School Admin' : 'Edit School Admin'
    },
  },
  watch: {
    dialogUser (val) {
      val || this.close()
    },
    dialogDisable (val) {
      val || this.closeDisable()
    },
    dialogEnable (val) {
      val || this.closeEnable()
    },
    dialogLoad (val) {
      console.log(val)
      // if (!val) return
      // console.log(this.$nuxt.isOffline);
      // setTimeout(() => (this.dialogLoad = false), 4000)
    },
  }
}
</script>
<style>
.disableUser td:not(td:last-child){
  text-decoration: line-through;
  color: #CFCFCF;
}
</style>